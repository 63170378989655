
const APPS = {
    '10': {
        id: '0',
        rank: 1,
        name: 'apps',
        label: 'Applications',
        type: 'list',    // Data Type     : list, object, map, string, number, float, date, url
        format: 'icon', // Component Type: profile, map, 
        description: 'Applications for data access',
        style: '',

        schema: [
            { id: '10-0', type: 'url', name: 'logo', label: 'Icon', format: 'icon' },
            { id: '10-1', type: 'url', name: 'path', label: 'Path', format: 'url' },
            { id: '10-2', type: 'string', name: 'name', label: 'Name', format: 'Text' }
        ]
    },
    'search': {
        id: 'search',
        name: 'Search',
        path: '/apps/search',
        icon: '/resources/images/icons/search.png',
        description: 'Search object types, topics and view object details',
        navbar: {
            search: true,
            logo: ''
        },
        types: [
            {
                id: 'object',
                icon: '',
                name: 'Object',
            }
        ],
        topics: [
            { id: 'one', name: 'One', percent: 50 },
            { id: 'two', name: 'Two', percent: 30 },
            { id: 'three', name: 'Tree', percent: 90 },
            { id: 'four', name: 'Four', percent: 20 },
            { id: 'five', name: 'Five', percent: 80 },
        ],
        security: [],
    }
}


const SCHEMA = {
    ...APPS,
    '0': {
        id: '0',
        rank: 1,
        name: 'domain',
        label: 'Domain',
        type: 'object',    // Data Type     : list, object, map, string, number, float, date, url
        format: 'profile', // Component Type: profile, map, 
        description: 'Website domains such as google.com.',
        style: '',

        schema: [
            { id: '1', type: 'string', name: 'qrcode', label: 'QRCode' },
            { id: '2', type: 'list', name: 'trackers', label: 'Trackers' },
            { id: '3', type: 'list', name: 'accounts', label: 'Accounts' },
            { id: '4', type: 'list', name: 'breaches', label: 'Breaches' },
            { id: '5', type: 'list', name: 'leaks', label: 'Leaks' },
            { id: '6', type: 'list', name: 'squatting', label: 'QRCode' },
            { id: '7', type: 'list', name: 'whois', label: 'Whois' },
            { id: '8', type: 'list', name: 'dns', label: 'DNS' },
            { id: '9', type: 'list', name: 'topics', label: 'Topics' },
            { id: '10', type: 'list', name: 'people', label: 'QRCode' },
            { id: '11', type: 'list', name: 'urls', label: 'URLs' },
            { id: '12', type: 'list', name: 'images', label: 'Images' },
            { id: '13', type: 'list', name: 'files', label: 'Files' },
            { id: '14', type: 'list', name: 'devices', label: 'Devices' },
            { id: '15', type: 'list', name: 'organizations', label: 'Organizations' },
        ]
    },
    '1': {
        id: '1',
        rank: 4,
        name: 'qrcode',
        label: 'QRCode',
        type: 'url',    // Data Type      : list, object, map, string, number, float, date, url
        format: 'image', // Component Type: profile, map, 
        description: 'QRCode for this object',
        style: '',

        schema: [],
        regex: [],
    },
    '2': {
        id: '2',
        rank: 2,
        type: 'list',    // list, object, string, number, float, rel
        name: 'trackers',
        label: 'Trackers',
        description: 'Website user tracking sources',
        format: 'list', // profile, list, table, tree, column
        style: '',
        schema: [],
    },
    '3': {
        id: '3',
        rank: 1,
        type: 'list',    // list, object, string, number, float, rel
        name: 'Accounts',
        description: 'Accounts of users on this website',
        format: 'table', // profile, list, table, tree, column
        style: '',
        schema: [
            { id: '1', type: 'string', name: 'qrcode', label: 'QRCode' },
            { id: '2', type: 'list', name: 'trackers', label: 'Trackers' },
            { id: '3', type: 'list', name: 'accounts', label: 'Accounts' },
            { id: '4', type: 'list', name: 'breaches', label: 'Breaches' },
            { id: '5', type: 'list', name: 'leaks', label: 'Leaks' },
            { id: '6', type: 'list', name: 'squatting', label: 'QRCode' },
            { id: '7', type: 'list', name: 'whois', label: 'Whois' },
            { id: '8', type: 'list', name: 'dns', label: 'DNS' },
            { id: '9', type: 'list', name: 'topics', label: 'Topics' },
            { id: '10', type: 'list', name: 'people', label: 'QRCode' },
            { id: '11', type: 'list', name: 'urls', label: 'URLs' },
            { id: '12', type: 'list', name: 'images', label: 'Images' },
            { id: '13', type: 'list', name: 'files', label: 'Files' },
            { id: '14', type: 'list', name: 'devices', label: 'Devices' },
            { id: '15', type: 'list', name: 'organizations', label: 'Organizations' },
        ],
    },
}




export default SCHEMA