import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Result, Button } from 'antd'
import AppLayout from 'components/layout/App'

const APP_SCHEMA = {
  id: '',
  name: '',
  path: '',
  icon: '',
  description: '',
  navbar: {
    search: false,
    logo: ''
  }
}


export default class Index extends PureComponent {
  render() {
    return (
      <AppLayout {...APP_SCHEMA}>
        <div
          style={{
            minHeight: 'calc(100vh - 500px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Helmet title="Not Found" />
          <div
            style={{
              maxWidth: '560px',
              backgroundColor: '',
              padding: '0px 0px',
              margin: '0px auto',
              borderRadius: '10px',
              flex: '1',
            }}
          >
            <div
              style={{
                maxWidth: '400px',
                margin: '0 auto',
              }}
            >
              <Result
                status="404"
                title="Page not found"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                  <Link to="/" className="btn">
                    <Button to="/" type="primary" className="btn">
                      Go back to the home page
                    </Button>
                  </Link>
                }
              />
            </div>
          </div>
        </div>
      </AppLayout>
    )
  }
}
