import React, { useState } from 'react'

import { Form, Button, Input, Checkbox, Select, Radio, Modal } from 'antd'
import {
    PhoneOutlined,
    LockOutlined,
    MailOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons'

import styles from './style.module.scss'

const { Option } = Select


const RegisterForm = ({ Signup, onSuccess }) => {
    const [visibleTerms, showTerms] = useState(false);
    const [terms, setTerms] = useState(false);
    const [form] = Form.useForm()
    console.log(onSuccess)

    const onFinish = values => {
        Signup(...values)
        onSuccess()
    }

    const months = [
        { value: '1', text: 'January' },
        { value: '2', text: 'February' },
        { value: '3', text: 'March' },
        { value: '4', text: 'April' },
        { value: '5', text: 'May' },
        { value: '6', text: 'June' },
        { value: '7', text: 'July' },
        { value: '8', text: 'August' },
        { value: '9', text: 'September' },
        { value: '10', text: 'October' },
        { value: '11', text: 'November' },
        { value: '12', text: 'December' },
    ]

    const days = []
    for (let i = 1; i <= 31; i += 1) {
        days.push(i.toString())
    }

    const years = []
    for (let i = 1900; i <= 2020; i += 1) {
        years.push(i.toString())
    }

    return (
        <Form
          form={form}
          name="access_signup"
          className={styles.LoginForm}
          initialValues={{ type: 'user' }}
          onFinish={onFinish}
          layout="vertical"
        >
            <Form.Item
              name="type"
              rules={[{ required: true, message: 'What type of account do you want?' }]}
            >
                <Radio.Group defaultValue="user">
                    <Radio.Button value="user">User</Radio.Button>
                    <Radio.Button value="business">Business</Radio.Button>
                    <Radio.Button value="government">Government</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                    return ['business', 'government'].includes(getFieldValue('type')) ? (
                        <>
                            <Form.Item
                              name="org"
                              rules={[{ required: true, message: "What is your organization's name?" }]}
                            >
                                <Input
                                  on
                                  prefix={<UsergroupAddOutlined className="site-form-item-icon" />}
                                  placeholder="Organization name"
                                />
                            </Form.Item>
                            <Form.Item
                              name="position"
                              rules={[{ required: true, message: 'What is your position in the organization?' }]}
                            >
                                <Input className="site-form-item-icon" placeholder="Your position" />
                            </Form.Item>
                        </>
                    ) : null
                }}
            </Form.Item>
            <Form.Item name="firstname" rules={[{ required: true, message: 'What is your first name?' }]}>
                <Input placeholder="First name" />
            </Form.Item>
            <Form.Item name="lastname" rules={[{ required: true, message: 'What is your last name?' }]}>
                <Input placeholder="Last name" />
            </Form.Item>
            <Form.Item
              name="phone"
              prefix={<PhoneOutlined className="site-form-item-icon" />}
              rules={[{ required: true, message: 'Required to reset your password' }]}
            >
                <Input placeholder="Phone name" />
            </Form.Item>
            <Form.Item name="email" rules={[{ required: true, message: 'What is your email address?' }]}>
                <Input
                  type="email"
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email Address"
                />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Create your password!' }]}>
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
            </Form.Item>
            <Form.Item name="re-password" rules={[{ required: true, message: 'Confirm your password!' }]}>
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Confirm Password"
                />
            </Form.Item>
            <Form.Item>
                <Input.Group compact>
                    <Form.Item
                      name={['dob', 'month']}
                      rules={[{ required: true, message: 'Required!' }]}
                      style={{ display: 'inline-block', width: 'calc(33%)' }}
                    >
                        <Select className="ant-input-number-input" placeholder="Month">
                            {months.map(d => (
                                <Option value={d.value}>{d.text}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                      name={['dob', 'day']}
                      rules={[{ required: true, message: 'Required!' }]}
                      style={{ display: 'inline-block', width: 'calc(33%)' }}
                    >
                        <Select className="ant-input-number-input" placeholder="Day">
                            {days.map(i => (
                                <Option value={i}>{i}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                      name={['dob', 'year']}
                      rules={[{ required: true, message: 'Required!' }]}
                      style={{ display: 'inline-block', width: 'calc(33%)' }}
                    >
                        <Select className="ant-input-number-input" placeholder="Year">
                            {years.reverse().map(i => (
                                <Option value={i}>{i}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Input.Group>
            </Form.Item>
            <Form.Item name="terms" rules={[{ required: true, message: 'Review the Terms?' }]}>
                <Checkbox checked={terms} disabled={!terms} onChange={(e) => setTerms(e.target.checked)}>I have read and agree to the &nbsp;
                <Button ghost shape="round" size="small" type="primary" onClick={() => showTerms(true)}>Terms</Button>
                </Checkbox>
                <TermsForm visible={visibleTerms} setTerms={setTerms} showTerms={showTerms} />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" className={styles.LoginFormButton}>
                    Sign Up
                </Button>
            </Form.Item>
        </Form>
    )
}


const TermsForm = ({ visible, setTerms, showTerms }) => {

    const setAgree = (v) => {
        setTerms(v)
        showTerms(false)
    }

    return (
        <Modal
          title="Terms and Conditions"
          centered
          visible={visible}
          onOk={() => setAgree(true)}
          onCancel={() => setAgree(false)}
          width="80%"
          cancelText="Disagree"
          okText="I Agree"
        >
            <h1>Terms & Conditions</h1>
            <p>some contents...</p>
            <p>some contents...</p>
            <p>some contents...</p>
            <p>some contents...</p>
            <p>some contents...</p>
            <h1>Privacy Policy</h1>
            <p>some contents...</p>
            <p>some contents...</p>
            <p>some contents...</p>
            <p>some contents...</p>
            <p>some contents...</p>
        </Modal>
    )
}

export default RegisterForm

