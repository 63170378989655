import React, { useState, createContext, useEffect } from 'react'

import  objectList from './data'


const SearchContext = createContext()


export const SearchProvider = ({ children }) => {
  const [search, setQuery] = useState({ query: '', topics: [], types: [] })
  const [results, getResults] = useState([])
  useEffect(() => {
    console.log('getResults()', search.query)
    if (search.query.length > 0) {
      getResults(objectList)
    }
  }, [search])
  return <SearchContext.Provider value={{ ...search, results, setQuery }}>{children}</SearchContext.Provider>
}

export const SearchConsumer = SearchContext.Consumer
export default SearchContext
