import React, { useContext } from 'react'
// import { Link } from 'react-router-dom'
import { Drawer, Button, notification } from 'antd'
import { FormattedMessage } from 'react-intl'

import UserContext, { defaultUser } from 'components/context/UserContext'
import AccessPanel from 'components/panels/AccessPanel'
import { AppIcons } from 'components/icons/app'

import styles from './style.module.scss'

const AppMenuPanelButtons = ({ onLogin, onLogout }) => {
  const { auth, authorized, updateUser } = useContext(UserContext)

  const logout = () => {
    auth
      .signOut()
      .then(() => {
        updateUser(defaultUser)
        onLogout()
      })
      .catch(error => {
        notification.warning({
          message: error.code,
          description: error.message,
        })
      })
  }

  if (authorized) {
    return (
      <div>
        <Button onClick={logout} type="link">
          Logout
        </Button>
      </div>
    )
  }
  return (
    <div>
      <Button onClick={onLogin} type="link">
        Login
      </Button>
    </div>
  )
}

class AppMenuPanel extends React.Component {
  state = { visible: false, login: false }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  hideDrawer = () => {
    this.setState({
      visible: false,
    })
  }

  showLogin = () => {
    this.setState({
      login: true,
    })
  }

  hideLogin = () => {
    this.setState({
      login: false,
    })
  }

  render() {
    const { visible, login } = this.state

    const { children } = this.props
    return (
      <React.Fragment>
        <Button type="link" onClick={this.showDrawer}>
          {children}
        </Button>
        <Drawer
          title={<FormattedMessage id="apps.title.applications" />}
          bodyStyle={{ padding: '5px' }}
          className={styles.appDrawer}
          placement="right"
          onClose={this.hideDrawer}
          visible={visible}
          key="menu"
          keyboard
          maskStyle={{ background: 'url("/resources/images/bg/info.png") repeat' }}
          footer={<AppMenuPanelButtons onLogin={this.showLogin} onLogout={this.hideDrawer} />}
        >
          <AccessPanel show={login} showDrawer={this.showLogin} hideDrawer={this.hideLogin} />
          <AppIcons onAuthorized={this.showLogin} />
        </Drawer>
      </React.Fragment>
    )
  }
}

export default AppMenuPanel
