import React, { useContext } from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
// import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/layout/Loader'
import UserContext from 'components/context/UserContext'
import NotFoundPage from 'views/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const ROUTES = [
  // Business Pages
  {
    path: '/company',
    component: loadable(() => import('views/Landing')),
    exact: true,
  },
  {
    path: '/terms',
    component: loadable(() => import('views/Terms')),
    exact: true,
  },
  {
    path: '/privacy',
    component: loadable(() => import('views/Privacy')),
    exact: true,
  }
]

const Router = () => {
  const { apps } = useContext(UserContext)
  const App = loadable(() => import('app'))
  const routes = [...ROUTES, ...apps.map(app => {
    return {
      path: app.path,
      component: () => <App {...app} />
    }
  })]
  return (
    <BrowserRouter>
      <Switch>
        <Route key="root" exact path="/" render={() => <Redirect to="/company" />} />
        {routes.map(route => (
          <Route
            path={route.path}
            component={route.component}
            key={route.path}
            exact={route.exact}
          />
        ))}
        <Route key="NotFound" component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  )
}

export default Router
