import React, { useState, createContext } from 'react'

import SCHEMA from './schema'

/*

ListViews
    Map
    List
    Table
    Column
    Hexagonal
    



DetailViews
    Map
    File
    Shell
    Image
    Video
    Audio
    Profile
*/

const SchemaContext = createContext()


export const SchemaProvider = ({ children }) => {
    const [localSchema, setSchema] = useState({})
    console.log(setSchema)

    const getSchema = (id) => {
        // Return schema if available
        if (localSchema[id] !== undefined) {
            return localSchema[id]
        }

        // Update global schema state
        const schema = { ...localSchema }
        schema[id] = SCHEMA[id]
        console.log('schemas', schema[id])
        setSchema(schema)

        console.log('schemas', localSchema[id])

        // Return the requested schema
        return localSchema[id]
    }

    return <SchemaContext.Provider value={{ getSchema }}>{children}</SchemaContext.Provider>
}

export const SchemaConsumer = SchemaContext.Consumer
export default SchemaContext
