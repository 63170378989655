import React, { useContext, useState } from 'react'
import { Input, Avatar, Button, Tabs, Drawer } from 'antd'
// import { FormattedMessage } from 'react-intl';
import { MenuUnfoldOutlined } from '@ant-design/icons'

import SearchContext from 'components/context/SearchContext'
import styles from './style.module.scss'

const { TabPane } = Tabs
const { Search } = Input


export const SearchFilter = ({ visible, onClose, style }) => {
  if (visible) {
    return (
      <Drawer
        closable
        keyboard
        height="50vh"
        style={style}
        placement="bottom"
        onClose={onClose}
        visible={visible}
        bodyStyle={{marginTop: 0, paddingTop: 0}}
      >
        <Tabs defaultActiveKey="2" style={{padding: 0}} tabBarExtraContent={<Search placeholder="Search.." style={{ width: 300, margin: '0px' }} />}>
          <TabPane
            style={{marginTop: 0, paddingTop: 0}}
            tab="Types"
            key="types"
          >
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
          </TabPane>
          <TabPane
            tab="Topics"
            key="topics"
          >
            Tab 2
          </TabPane>
        </Tabs>
      </Drawer>
    )
  }
  return null
}

const SearchInput = ({ visible, className, style }) => {
  const { query, topics, types, objects, setQuery } = useContext(SearchContext)
  const [filters, showFilters] = useState(false)

  const onSearch = e => {
    setQuery({ query: e.target.value, topics, types, objects })
  }

  const Menu = () => {
    return (
      <Avatar>
        <Button shape="circle" icon={<MenuUnfoldOutlined />} onClick={() => showFilters(!filters)} />
      </Avatar>
    )
  }

  if (visible) {
    return (
      <>
        <div className={className} style={style}>
          <Input
            autoFocus
            prefix={
              <Avatar.Group size="large">
                <Avatar src="/resources/images/logos/logo-mobile.png" />
              </Avatar.Group>
            }
            suffix={<Menu />}
            placeholder="Search..."
            defaultValue={query}
            onPressEnter={onSearch}
            // onChange={onSearch}
            className={styles.SearchInput}
          />
        </div>
        <SearchFilter visible={filters} onClose={() => showFilters(false)} />
      </>
    )
  }
  return null
}

export default SearchInput

