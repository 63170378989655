import React, { useContext } from 'react'

import { Drawer, Form, Button, Input, Checkbox, Tabs, Typography } from 'antd'
import {
  UserOutlined,
  LockOutlined,
} from '@ant-design/icons'

import  UserContext  from 'components/context/UserContext'
import RegisterForm from 'components/forms/Register'
import styles from './style.module.scss'

const { TabPane } = Tabs
const { Link } = Typography

const LoginForm = props => {
  const { Login, onSuccess } = props

  const onFinish = values => {
    const { email, password } = values
    Login(email, password)

    // auth
    //   .signInWithEmailAndPassword(email, password)
    //   .then(userCredential => {
    //     updateUser(userCredential.user)
    //   })
    //   .catch(error => {
    //     notification.warning({
    //       message: error.code,
    //       description: error.message,
    //     })
    //   })

    onSuccess()
  }

  return (
    <Form
      name="access_login"
      className={styles.LoginForm}
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your email address!' }]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className={styles.LoginFormButton}>
          Log in
        </Button>
      </Form.Item>
    </Form>
  )
}

// const RegisterForm = ({ Signup, onSuccess }) => {
//   const [terms, setTerms] = useState(false);
//   const [form] = Form.useForm()
//   console.log(onSuccess)

//   const onFinish = values => {
//     Signup(...values)
//     onSuccess()
//   }

//   const months = [
//     { value: '1', text: 'January' },
//     { value: '2', text: 'February' },
//     { value: '3', text: 'March' },
//     { value: '4', text: 'April' },
//     { value: '5', text: 'May' },
//     { value: '6', text: 'June' },
//     { value: '7', text: 'July' },
//     { value: '8', text: 'August' },
//     { value: '9', text: 'September' },
//     { value: '10', text: 'October' },
//     { value: '11', text: 'November' },
//     { value: '12', text: 'December' },
//   ]

//   const days = []
//   for (let i = 1; i <= 31; i += 1) {
//     days.push(i.toString())
//   }

//   const years = []
//   for (let i = 1900; i <= 2020; i += 1) {
//     years.push(i.toString())
//   }

//   return (
//     <Form
//       form={form}
//       name="access_signup"
//       className={styles.LoginForm}
//       initialValues={{ type: 'user' }}
//       onFinish={onFinish}
//       layout="vertical"
//     >
//       <Form.Item
//         name="type"
//         rules={[{ required: true, message: 'What type of account do you want?' }]}
//       >
//         <Radio.Group defaultValue="user">
//           <Radio.Button value="user">User</Radio.Button>
//           <Radio.Button value="business">Business</Radio.Button>
//           <Radio.Button value="government">Government</Radio.Button>
//         </Radio.Group>
//       </Form.Item>
//       <Form.Item noStyle shouldUpdate>
//         {({ getFieldValue }) => {
//           return ['business', 'government'].includes(getFieldValue('type')) ? (
//             <>
//               <Form.Item
//                 name="org"
//                 rules={[{ required: true, message: "What is your organization's name?" }]}
//               >
//                 <Input
//                   on
//                   prefix={<UsergroupAddOutlined className="site-form-item-icon" />}
//                   placeholder="Organization name"
//                 />
//               </Form.Item>
//               <Form.Item
//                 name="position"
//                 rules={[{ required: true, message: 'What is your position in the organization?' }]}
//               >
//                 <Input className="site-form-item-icon" placeholder="Your position" />
//               </Form.Item>
//             </>
//           ) : null
//         }}
//       </Form.Item>
//       <Form.Item name="firstname" rules={[{ required: true, message: 'What is your first name?' }]}>
//         <Input placeholder="First name" />
//       </Form.Item>
//       <Form.Item name="lastname" rules={[{ required: true, message: 'What is your last name?' }]}>
//         <Input placeholder="Last name" />
//       </Form.Item>
//       <Form.Item
//         name="phone"
//         prefix={<PhoneOutlined className="site-form-item-icon" />}
//         rules={[{ required: true, message: 'Required to reset your password' }]}
//       >
//         <Input placeholder="Phone name" />
//       </Form.Item>
//       <Form.Item name="email" rules={[{ required: true, message: 'What is your email address?' }]}>
//         <Input
//           type="email"
//           prefix={<MailOutlined className="site-form-item-icon" />}
//           placeholder="Email Address"
//         />
//       </Form.Item>
//       <Form.Item name="password" rules={[{ required: true, message: 'Create your password!' }]}>
//         <Input
//           prefix={<LockOutlined className="site-form-item-icon" />}
//           type="password"
//           placeholder="Password"
//         />
//       </Form.Item>
//       <Form.Item name="re-password" rules={[{ required: true, message: 'Confirm your password!' }]}>
//         <Input
//           prefix={<LockOutlined className="site-form-item-icon" />}
//           type="password"
//           placeholder="Confirm Password"
//         />
//       </Form.Item>
//       <Form.Item>
//         <Input.Group compact>
//           <Form.Item
//             name={['dob', 'month']}
//             rules={[{ required: true, message: 'Required!' }]}
//             style={{ display: 'inline-block', width: 'calc(33%)' }}
//           >
//             <Select className="ant-input-number-input" placeholder="Month">
//               {months.map(d => (
//                 <Option value={d.value}>{d.text}</Option>
//               ))}
//             </Select>
//           </Form.Item>
//           <Form.Item
//             name={['dob', 'day']}
//             rules={[{ required: true, message: 'Required!' }]}
//             style={{ display: 'inline-block', width: 'calc(33%)' }}
//           >
//             <Select className="ant-input-number-input" placeholder="Day">
//               {days.map(i => (
//                 <Option value={i}>{i}</Option>
//               ))}
//             </Select>
//           </Form.Item>
//           <Form.Item
//             name={['dob', 'year']}
//             rules={[{ required: true, message: 'Required!' }]}
//             style={{ display: 'inline-block', width: 'calc(33%)' }}
//           >
//             <Select className="ant-input-number-input" placeholder="Year">
//               {years.reverse().map(i => (
//                 <Option value={i}>{i}</Option>
//               ))}
//             </Select>
//           </Form.Item>

//           <Form.Item name="terms" rules={[{ required: true, message: 'Do you agree to the Terms?' }]}>
//             <Checkbox onChange={(e)=>setTerms(e.target.checked)}>I have read and agree to the Terms</Checkbox>
//           </Form.Item>
//           <Modal
//             title="Terms and Conditions"
//             centered
//             visible={terms}
//             onOk={() => setTerms(false)}
//             onCancel={() => setTerms(false)}
//             width="80%"
//             cancelText="Cancel"
//             okText="Agree"
//           >
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//             <p>some contents...</p>
//           </Modal>


//         </Input.Group>
//       </Form.Item>
//       <Form.Item>
//         <Button type="primary" htmlType="submit" className={styles.LoginFormButton}>
//           Sign Up
//         </Button>
//       </Form.Item>
//     </Form>
//   )
// }

const ForgotForm = ({ Reset }) => {
  const onFinish = ({ id }) => {
    Reset(id)
  }

  return (
    <Form
      name="access_forgot"
      className={styles.LoginForm}
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item name="id" rules={[{ required: true, message: 'Username or phone number!' }]}>
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email / Phone Number"
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className={styles.LoginFormButton}>
          Send
        </Button>
      </Form.Item>
    </Form>
  )
}

const AccessPanel = ({ children, show, showDrawer, hideDrawer }) => {
  const { auth, Login, Signup, Reset } = useContext(UserContext)

  return (
    <>
      <Link type="primary" onClick={showDrawer}>
        {children}
      </Link>
      <Drawer
        title="Account Access"
        width={320}
        onClose={hideDrawer}
        visible={show}
        keyboard
        className={styles.loginDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        maskStyle={{ background: 'url("/resources/images/bg/warn.png") repeat' }}
      >
        <Tabs defaultActiveKey="1" tabPosition="bottom" style={{ minHeight: '100%' }} centered>
          <TabPane tab="Login" key="1">
            <LoginForm Login={Login} auth={auth} onSuccess={hideDrawer} />
          </TabPane>
          <TabPane tab="Signup" key="2">
            <RegisterForm Signup={Signup} auth={auth} onSuccess={hideDrawer} />
          </TabPane>
          <TabPane tab="Forgot" key="3">
            <ForgotForm Reset={Reset} auth={auth} />
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  )
}

export default AccessPanel
