import React, { useState, useEffect } from 'react'
import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDhlXmtpEN4PQ1r0TCanwO4KskRYYpA30k',
  authDomain: 'cybint.firebaseapp.com',
  databaseURL: 'https://cybint.firebaseio.com',
  projectId: 'cybint',
  storageBucket: 'cybint.appspot.com',
  messagingSenderId: '385310621612',
  appId: '1:385310621612:web:abee78dd265f9465e90409',
  measurementId: 'G-XBSLZSCKPY',
}

firebase.initializeApp(firebaseConfig)
// firebase.analytics()
const auth = firebase.auth()
auth.useDeviceLanguage()
export const  firestore = firebase.firestore()

// export default firebaseApp
const UserContext = React.createContext()


export const DefaultApps = [
  {
    path: '/apps/news',
    icon: '/resources/images/icons/news.jpeg',
    authorized: true,
    name: 'News',
    id: 'news',
    description: 'Global News',
  },
  {
    path: '/apps/search',
    icon: '/resources/images/icons/search.png',
    authorized: true,
    name: 'Search',
    id: 'search',
    description: 'Searching for intelligence',
  },
  {
    path: '/apps/account',
    icon: '/resources/images/avatars/anon.jpg',
    authorized: true,
    name: 'Account',
    id: 'accounts',
    description: 'Account management',
  }

]
export const defaultUser = {
  uid: '',
  name: 'Anonymous',
  role: '',
  email: '',
  avatar: '/resources/images/avatars/anon.jpg',
  verified: false,
  loading: false,
  locale: navigator.language || 'en-US',

  apps: DefaultApps,
}

const User = authUser => {
  if (authUser != null) {
    return {
      id: authUser.uid,
      email: authUser.email,
      avatar: authUser.photoURL,
      name: authUser.displayName,
      authorized: true,
      verified: authUser.emailVerified,
      locale: authUser.languageCode || defaultUser.locale,
      loading: authUser.loading,
      role: authUser.role,
      apps: defaultUser.apps,
    }
  }
  return defaultUser
}


export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(defaultUser)
  useEffect(() => {
    auth.onAuthStateChanged(userAuth => {
      setUser(User(userAuth))
    })
  }, [])

  const Login = (email, password) => {
    return auth
      .signInWithEmailAndPassword(email, password)
      .then((creds) => {
        setUser(User(creds.user))
      })
      .catch(error => {
        notification.warning({
          message: error.code,
          description: error.message,
        })
      })
  }

  const Signup = values => {
    const { email, password } = values
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then((creds) => {
        setUser(User(creds.user))
      })
      .catch(error => {
        notification.warning({
          message: error.code,
          description: error.message,
        })
      })
  }

  const Logout = () => {
    return auth
      .signOut()
      .then(() => {
        setUser(User())
      })
      .catch(error => {
        notification.warning({
          message: error.code,
          description: error.message,
        })
      })
  }

  const Reset = (email) => {
    auth.sendPasswordResetEmail(email)
    notification.info({
      message: 'If you have an account you will receive password reset instructions!'
    })
  }

  return (
    <UserContext.Provider
      value={{
        ...user,
        auth,
        Reset,
        Login,
        Logout,
        Signup,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const UserConsumer = UserContext.Consumer
export default UserContext
