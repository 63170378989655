import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Layout, Menu } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

// import ContextPanel from 'components/panels/ContextPanel'
import AppMenuPanel from 'components/panels/AppPanel'
import SearchInput, { SearchFilter } from 'components/layout/Search'
import Footer from 'components/layout/Footer'
// import { Type } from 'components/antd'

import styles from './style.module.scss'

// console.log(Type)

const { Header } = Layout

const AppLayout = ({ children, name, navbar }) => {
  const [height, setHeight] = useState(window.innerHeight)
  const [filter, setFilter] = useState(false)
  // const [context, setContext] = useState(false) onMenu={() => setContext(!context)}

  const updateDimensions = () => {
    setHeight(window.innerHeight)
  }
  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  // const ctx = 'contextmenu'
  // document.addEventListener(ctx, (i) => {i.preventDefault()})

  return (
    <Layout style={{ minHeight: height }} theme="light">
      <Helmet title={name} />
      <Header style={{ padding: 0, background: '#f2f4f8', position: 'fixed', zIndex: 1, width: '100%' }}>
        <SearchInput visible={navbar.search} className={styles.SearchInput} onMenu={()=>setFilter(!filter)} />
        <Menu theme="light" mode="horizontal" style={{ background: 'transparent', float: 'right' }}>
          <Menu.Item key="2" style={{ float: 'right', border: '0px' }}>
            <AppMenuPanel>
              <MenuOutlined style={{ fontSize: '18px', color: '#000' }} />
            </AppMenuPanel>
          </Menu.Item>
        </Menu>
      </Header>
      <SearchFilter visible={filter} />
      <Layout className={styles.AppLayout} style={{ padding: '0', marginTop: 50, backgroundColor: '#f2f4f8' }}>
        {children}
      </Layout>
      <Footer />
    </Layout>
  )
}

export default AppLayout
