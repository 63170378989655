import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, Row, Col, Layout } from 'antd'
import { FormattedMessage } from 'react-intl'
import LanguageSelector from 'components/layout/Language'

import styles from './style.module.scss'

const { Footer } = Layout

const LayoutFooter = () => (
  <Footer className={styles.footer}>
    <Row>
      <Col span={8} order={1} />
      <Col span={7} order={2} style={{ textAlign: 'center' }}>
        <Link to="/company" target="_blank">
          CYBINT&nbsp;LLC,&nbsp;
        </Link>
        <FormattedMessage id="footer.copyrightText" />
      </Col>
      <Col span={8} order={3} style={{ textAlign: 'right' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/terms" target="_blank">
              <FormattedMessage id="footer.terms" />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/privacy" target="_blank">
              <FormattedMessage id="footer.privacy" />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <LanguageSelector />
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
    </Row>
  </Footer>
)

export default LayoutFooter
